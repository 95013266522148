import React, { useRef, useState, useEffect } from "react";
import { FaTimes, FaBars, FaPhone, FaWhatsapp, FaWaze, FaFax, FaLanguage } from 'react-icons/fa'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Fade } from 'react-reveal'

import '../Styles/Navbar.css'
import '../Styles/Home/sec-1.css'
import '../Styles/Home/sec-2-extra.css'
import '../Styles/Home/sec-2.css'
import '../Styles/Home/sec-3.css'
import '../Styles/Home/sec-4.css'
import '../Styles/Home/sec-5.css'
import '../Styles/Home/sec-6.css'
import '../Styles/Home/sec-7.css'

import ImageSlider from "./Images";
import Logo from '../Images/Logo.jpg'

//image slider 1
import I1 from '../Images/Catalog/Home/web/I1.webp'
import I2 from '../Images/Catalog/Home/web/I2.webp'
import I3 from '../Images/Catalog/Home/web/I3.webp'
import I4 from '../Images/Catalog/Home/web/I4.webp'
import I5 from '../Images/Catalog/Home/web/I5.webp'
import I6 from '../Images/Catalog/Home/web/I6.webp'
import I7 from '../Images/Catalog/Home/web/I7.webp'
import I8 from '../Images/Catalog/Home/web/I8.webp'
import I9 from '../Images/Catalog/Home/web/I9.webp'
import I10 from '../Images/Catalog/Home/web/I10.webp'
import I11 from '../Images/Catalog/Home/web/I11.webp'

//image slider 2
import I12 from '../Images/Catalog/Home/web/I12.webp'
import I13 from '../Images/Catalog/Home/web/I13.webp'
import I14 from '../Images/Catalog/Home/web/I14.webp'
import I15 from '../Images/Catalog/Home/web/I15.webp'
import I16 from '../Images/Catalog/Home/web/I16.webp'
import I17 from '../Images/Catalog/Home/web/I17.webp'
import I18 from '../Images/Catalog/Home/web/I18.webp'
import I19 from '../Images/Catalog/Home/web/I19.webp'
import I20 from '../Images/Catalog/Home/web/I20.webp'
import I21 from '../Images/Catalog/Home/web/I21.webp'
import I22 from '../Images/Catalog/Home/web/I22.webp'
import I23 from '../Images/Catalog/Home/web/I23.webp'

//image slider 3/web
import I24 from '../Images/Catalog/Home/web/I24.webp'
import I25 from '../Images/Catalog/Home/web/I25.webp'
import I26 from '../Images/Catalog/Home/web/I26.webp'
import I27 from '../Images/Catalog/Home/web/I27.webp'
import I28 from '../Images/Catalog/Home/web/I28.webp'
import I29 from '../Images/Catalog/Home/web/I29.webp'
import I30 from '../Images/Catalog/Home/web/I30.webp'
import I31 from '../Images/Catalog/Home/web/I31.webp'
import I32 from '../Images/Catalog/Home/web/I32.webp'
import I33 from '../Images/Catalog/Home/web/I33.webp'
import I34 from '../Images/Catalog/Home/web/I34.webp'
import I35 from '../Images/Catalog/Home/web/I35.webp'
import I36 from '../Images/Catalog/Home/web/I36.webp'
import I37 from '../Images/Catalog/Home/web/I37.webp'
import I39 from '../Images/Catalog/Home/web/I39.webp'

import Video from '../Video/Vid1.webm'

import LanguagePopuo from "./LanguagePopup";

//Services
import S1 from "./Services/S1";
import S2 from "./Services/S2";
import S3 from "./Services/S3";
import S4 from "./Services/S4";

//Home-Images
import HomeImageSlider from "./HomeImages";
import H1 from '../Images/Home/NewImages/I1.webp'
import H2 from '../Images//Home/NewImages/I2.webp'
import H3 from '../Images/Home/NewImages/I3.webp'
import H4 from '../Images/Home/NewImages/I4.webp'
import H5 from '../Images/Home/NewImages/I5.webp'
import H6 from '../Images/Home/NewImages/I6.webp'
import H7 from '../Images/Home/NewImages/I7.webp'
import H8 from '../Images/Home/NewImages/I8.webp'
import H10 from '../Images/Home/NewImages/I10.webp'
import H11 from '../Images/Home/NewImages/I11.webp'
import H12 from '../Images/Home/NewImages/I12.webp'
import H14 from '../Images/Home/NewImages/I14.webp'

const Home = () => {

    const navRef = useRef()
    const nav = useNavigate()

    const {t} = useTranslation()

    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav")
    }

    //Scroll to section

    const scrollToSection = (sectionId, showNavbarFlag = false) => {
        const section = document.getElementById(sectionId);
        section.scrollIntoView({ behavior: 'smooth' });
        if (showNavbarFlag) {
            showNavbar();
        }
    };

    //useEffect
    //LanguagePopup
    const [popup, setPopup] = useState(false)

    useEffect(() => {

        setPopup(true)
        }, []);

        //Language popup
        const changeLanguage = () => {
            localStorage.setItem('popupVisible', true);
            showNavbar()
            window.location.reload()
        }

        const sliderImages1 = [
            I1, I2, I3, I4, I5, I6, I7, I8, I9, I10, I11
         ];

        const sliderImages2 = [
           I12, I13, I14, I14, I15, I16, I17, I18, I19, I20, I21, I22, I23
        ];

        const sliderImages3 = [
            I24, I25, I26, I27, I28, I29, I30, I31, I32, I33, I34, I35, I36, I37, I39
         ];

         const HomesliderImages1 = [
            H1 , H2 , H3 , H4 , H5 , H6 , H7 , H8 
         ];

         const HomesliderImages2 = [
            H10 , H11 , H12 , H14 
         ];

         const [activeButton, setActiveButton] = useState('S1');

         const handleButtonClick = (buttonName) => {
            setActiveButton(buttonName);
        };

         const renderMainInfoContent = () => {
            switch (activeButton) {
                case 'S1':
                    return <S1/>;
                case 'S2':
                    return <S2/>;
                case 'S3':
                    return <S3/>;
                case 'S4':
                    return <S4/>;
            
                default:
                    return null;
            }
        };
        
    return (
        <React.Fragment>

            <LanguagePopuo trigger={popup} setTrigger={setPopup}/>

            <header>
            <img src={Logo} alt='header logo' onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>

            <view className='space'/>

            <nav className='navbar' ref={navRef}>
                <div className='innav'>
                <img src={Logo} alt='header logo' onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>
                <button onClick={showNavbar} className='navbtn navhide'>
                    <FaTimes color="#00b9f2"/>
                </button>
                </div>
                <button onClick={() => {nav('/Catalog')}}>{t('Catalog')}</button>
                <button onClick={() => {scrollToSection('sec-5', true)}}>{t('Technologies')}</button>
                <button onClick={() => {scrollToSection('sec-2-extra', true)}}>{t('Services')}</button>
                <button onClick={() => {scrollToSection('sec-4', true)}}>{t('Aboutus')}</button>
                <button onClick={() => {scrollToSection('sec-6', true)}}>{t('Contact')}</button>
                <button onClick={changeLanguage}><FaLanguage size={30}/></button>
            </nav>
            <button onClick={showNavbar} className='navbtn navshow'>
                <FaBars color="#00b9f2"/>
            </button>
        </header>

        <section className="sec-1">
          <video autoPlay loop muted className="background-video" onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}>
            <source src={Video} type="video/mp4" />
          </video>
          <Fade>
          <div className="overlay-text">
            Plastic Rayan

            <h1 className="h2">We talk about Quality
            </h1>
            <svg xmlns="http://www.w3.org/2000/svg" width="414" height="4" viewBox="0 0 414 4" fill="none">
              <path d="M2 2H412.001" stroke="#FF6C23" stroke-width="4" stroke-linecap="round"/>
            </svg>

            <div className="sec-1-btn">

                <button onClick={() => {scrollToSection('sec-4', false)}}>About us</button>
                <button onClick={() => {scrollToSection('sec-6', false)}}>Contact</button>

            </div>

            <button className="CBtn" onClick={() => {scrollToSection('sec-2', false)}}>Catalog</button>
          </div>
          </Fade>
        </section>


        <section className="sec-2-extra" id="sec-2-extra">

        <Fade bottom duration={2500}>

            <h1 style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '20px'}}>{t('Services')}
            <svg xmlns="http://www.w3.org/2000/svg" width="309" height="4" viewBox="0 0 309 4" fill="none">
              <path d="M2 2H307" stroke="#00B9F2" stroke-width="4" stroke-linecap="round"/>
            </svg>
            </h1>
        </Fade>

        <div className="MainServices">

            <div className="ButtonsBlock">

                <button className={activeButton === 'S1' ? 'activeButton' : ''} onClick={() => handleButtonClick('S1')}>{t('S1')}</button>
                <button className={activeButton === 'S2' ? 'activeButton' : ''} onClick={() => handleButtonClick('S2')}>{t('S2')}</button>
                <button className={activeButton === 'S3' ? 'activeButton' : ''} onClick={() => handleButtonClick('S3')}>{t('S3')}</button>
                <button className={activeButton === 'S4' ? 'activeButton' : ''} onClick={() => handleButtonClick('S4')}>{t('S4')}</button>

            </div>

            <div className="ContentBlock">
                
                {renderMainInfoContent()}

            </div>

        </div>

        </section>

        <section className="sec-2" id="sec-2">

            <h1 style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '20px'}}>{t('Catalog')}
            <svg xmlns="http://www.w3.org/2000/svg" width="309" height="4" viewBox="0 0 309 4" fill="none">
              <path d="M2 2H307" stroke="#00B9F2" stroke-width="4" stroke-linecap="round"/>
            </svg>
            </h1>

            <div className="CatalogBlocks">

                <div>
                <ImageSlider images={sliderImages1} />
                </div>

                <div>
                <ImageSlider images={sliderImages2} />
                </div>

                <div>
                <ImageSlider images={sliderImages3} />
                </div>


            </div>

            <button onClick={() => {nav('Catalog')}}>{t('Catalog')}</button>

        </section>

        <section className="sec-3">

        </section>

        <section className="sec-4" id="sec-4">

        <svg xmlns="http://www.w3.org/2000/svg" width="526" height="4" viewBox="0 0 526 4" fill="none">
          <path d="M2 2H524" stroke="#00B9F2" stroke-width="4" stroke-linecap="round"/>
        </svg>

        <div className="AboutusContent">

        <Fade>


            <div className="block1">

                <HomeImageSlider images={HomesliderImages1}/>
                
            </div>

        </Fade>


            <div className="block2">

                <h1 style={{textAlign: t('alignment'), display: 'flex', flexDirection: 'column', gap: '5px'}}>{t('Aboutus')}
                <svg xmlns="http://www.w3.org/2000/svg" style={{alignSelf: t('SVG')}} width="210" height="4" viewBox="0 0 302 4" fill="none">
                  <path d="M2 2H300" stroke="#FF6C23" stroke-width="4" stroke-linecap="round"/>
                </svg>
                </h1>

                <p style={{textAlign: t('alignment')}}>{t('AP')}</p>

                <div className="AboutusBtn">

                    <button onClick={() => {scrollToSection('sec-6', false)}}>{t('Contact')}</button>
                    <button onClick={() => {scrollToSection('sec-2-extra', false)}}>{t('Services')}</button>

                </div>

            </div>


        </div>

        <svg xmlns="http://www.w3.org/2000/svg" width="526" height="4" viewBox="0 0 526 4" fill="none">
          <path d="M2 2H524" stroke="#FF6C23" stroke-width="4" stroke-linecap="round" stroke-dasharray="8 8"/>
        </svg>

        </section>

        <section className="sec-5" id="sec-5">

            <div className="Block3">

                <h1 style={{textAlign: t('alignment'), display: 'flex', flexDirection: 'column', gap: '5px'}}>{t('Technologies')}
                <svg xmlns="http://www.w3.org/2000/svg" style={{alignSelf: t('SVG')}} width="310" height="4" viewBox="0 0 343 4" fill="none">
                  <path d="M2 2H341" stroke="#FF6C23" stroke-width="4" stroke-linecap="round"/>
                </svg>
                </h1>

                <p style={{textAlign: t('alignment')}}>{t('TP')}</p>

                <div className="TechnologiesBtn">

                    <button onClick={() => {scrollToSection('sec-2', false)}}>{t('Catalog')}</button>
                    <button onClick={() => {scrollToSection('sec-6', false)}}>{t('Contact')}</button>

                </div>

            </div>


        <Fade>

            <div className="Block4">

            <HomeImageSlider images={HomesliderImages2}/>

            </div>

        </Fade>

        </section>

        <section className="sec-6" id="sec-6">

        <Fade bottom duration={2500}>

        <svg className="S1" xmlns="http://www.w3.org/2000/svg" width="526" height="4" viewBox="0 0 526 4" fill="none">
          <path d="M2 2H524" stroke="#00B9F2" stroke-width="4" stroke-linecap="round"/>
        </svg>

        <h1>{t('Contact')}</h1>

        <div className="MainContact">

            <div className="CBlock1">

                <a href="tel:+972525885759" style={{flexDirection: t('flex'), textAlign: t('alignment')}}><FaPhone size={30}/>{t('Phone')}</a>
                <a href="https://wa.me/+972525885759" style={{flexDirection: t('flex'), textAlign: t('alignment')}}><FaWhatsapp size={30}/>{t('Whats')}</a>
                <a href="https://www.google.com/maps/place/33%C2%B014'15.1%22N+35%C2%B045'11.0%22E/@33.23753,35.7504862,1063m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d33.23753!4d35.7530611?entry=ttu&g_ep=EgoyMDI1MDMwNC4wIKXMDSoASAFQAw%3D%3D" style={{flexDirection: t('flex'), textAlign: t('alignment')}}><FaWaze size={30}/>{t('Waze')}</a>
                <a href="fax:049843513" style={{flexDirection: t('flex'), textAlign: t('alignment')}}><FaFax size={30}/>{t('Fax')}: 04-9843513</a>

            </div>

            <div className="CBlock2">

                <input style={{textAlign: t('alignment')}} placeholder={t('Name')}/>
                <input style={{textAlign: t('alignment')}} placeholder={t('Phone')}/>
                <input style={{textAlign: t('alignment')}} placeholder={t('Email')}/>
                <input style={{textAlign: t('alignment')}} placeholder={t('Company')}/>

                <textarea style={{textAlign: t('alignment')}} placeholder={t('Message')}/>

                <button>{t('Send')}</button>

            </div>

        </div>

        </Fade>

        </section>

        <section className="sec-7">

            <div className="Main7">

                <div className="M7Block1">
                    <img alt="bottomlogo" src={Logo} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>
                    <h1 className="h2" style={{color: '#fff'}}>We talk about Quality</h1>

                </div>

                <div className="M7Block2">

                    <button onClick={() => {scrollToSection('sec-2', false)}}>{t('Catalog')}</button>
                    <button onClick={() => {scrollToSection('sec-5', false)}}>{t('Technologies')}</button>
                    <button onClick={() => {scrollToSection('sec-2-extra', false)}}>{t('Services')}</button>
                    <button onClick={() => {scrollToSection('sec-4', false)}}>{t('Aboutus')}</button>
                    <button onClick={() => {scrollToSection('sec-6', false)}}>{t('Contact')}</button>

                </div>

            </div>

            <p>Powered By <a href="https://devstation.pro/" target="._blank">devstation.pro</a></p>

        </section>
            
        </React.Fragment>
    )
}

export default Home